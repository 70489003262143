<template>
  <main class="content">
    <Hero title="Suwo" subtitle="Stedelijk Utrechtse Woonboten Overleg" show-subtitle />
    <div style="position: relative;">
      <div class="maxw--body z-1">
        <div class="maxw--waist block text--center rendered card--nb card index__intro" v-html="$md.render(data?.content || '')"></div>
        <!-- <LineOne class="line_uno"/> -->
        <div class="block grid--two gap--triple-ver">
          <div>
            <h2 class="heading-2 mb-2 text--center">FAQ</h2>
            <div class="maxw--waist">
              <FaqItem v-for="faq in faqItems" :key="faq.id" :question="faq.question" :answer="faq.answer"
                class="card--normal mx-auto" />
            </div>
            <div class="centered mt-2">
              <nuxt-link class="btn btn--flat btn--big" to="/faq">Meer veelgestelde vragen</nuxt-link>
            </div>
          </div>
          <div class="index__rightcolumn">
            <!-- <LineTwo class="line_dos"/> -->
            <h2 class="heading-2 mb-2 text--center">Tweets</h2>
            <Timeline id="SUWOUtrecht" widget-class="card card--np twitter-timeline" sourceType="profile"
              :options="{ theme: 'light', chrome: 'transparent noborders noheader nofooter', height: 500, tweetLimit: 8 }">
              <div class="loader-container">
                <Spinner class="twitter-loader" />
              </div>
            </Timeline>
          </div>
        </div>
        <!-- <LineThree class="line_tres"/> -->
        <div class="block">
          <h2 class="heading-2 mb-2 text--center">Recent - Blog</h2>
          <div class="grid--two">
            <BlogItem class="card--normal" v-for="blogItem in blogItems" :key="blogItem.id" :data="blogItem" />
          </div>
          <div class="centered mt-2">
            <nuxt-link class="btn btn--flat btn--big" to="/blog">Bekijk alle blogs</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import type BlogArticle from "@/typings/strapi/objects/blogArticle";
// Possible bugfix for the item: https://devcommunity.x.com/t/again-list-widget-says-nothing-to-see-here-yet-if-logged-out/198782/354
import { Timeline } from '@jacksongross/vue-tweet-embed';
import type FAQ from "@/typings/strapi/objects/faq";
import type HomePage from "@/typings/strapi/pages/homePage";

import LineOne from '~/assets/svg/line_1.svg?component'
import LineTwo from '~/assets/svg/line_2.svg?component'
import LineThree from '~/assets/svg/line_3.svg?component'

useHead({
  title: 'SUWO | Stedelijk Utrechts Woonschepen Overleg',
  titleTemplate: '%s'
});

const { find, findOne } = useStrapi();

const { data } = await useAsyncData(
  'home-page',
  () => findOne<HomePage>('home-page'),
  {
    transform: flattenObject
  }
)

const { data: blogItems, pending: blogItemsPending } = await useAsyncData(
  'blog-items-home',
  () => find<BlogArticle>('blog-articles', { pagination: { pageSize: 2, page: 1 }, sort: 'publishedAt:desc' }),
  {
    transform: flattenCollection,
    lazy: true
  }
)

const { data: faqItems, pending: faqItemsPending } = await useAsyncData(
  'faqs-home',
  () => find<FAQ>('faqs', { pagination: { pageSize: 5, page: 1 }, filters: { showOnHomepage: true }, sort: 'rank:asc' }),
  {
    transform: flattenCollection,
    lazy: true
  }
)

definePageMeta({
  pageTransition: pageTransitionConfig,
});
</script>

<style lang="scss">
.index__background {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  bottom: -2rem;
  overflow: visible;

  svg {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: -35%;
  }
}

.line_uno {
  stroke: $sea;
  height: 8rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  transform: translateX(-57%);
}

.line_dos {
  stroke: $sea;
  width: 522px;
  margin-top: -2rem;
  margin-bottom: 1rem;
}

.line_tres {
  stroke: $sea;
  width: 827px;
  margin-top: -8.5rem;
  margin-left: 96px;
  margin-bottom: 1rem;
}
</style>
